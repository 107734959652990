import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Home from './Home';
import Pricing from './Pricing';
import About from './About';
import Testimonials from './Testimonials';
import Quote from './Quote';
import SuccessPage from './SuccessPage';
import Footer from './Footer';
import CancelPage from './CancelPage';
import ContactForm from './Contact';
import ScrollToTop from './ScrollToTop';
import Services from './Services';
import Portfolio from './Portfolio';
import Booking from './Booking';
import NotFound from './NotFound';

// A wrapper to protect routes
const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  if (!location.state?.fromPayment) {
    return <Navigate to="/" replace />;
  }
  return children;
};

function App() {
  return (
    <>
      <Helmet>
        <title>Royol Technology | Affordable Websites and Innovative Digital Solutions</title>
        <meta 
          name="description" 
          content="Royol Technology specializes in building modern websites, providing web development, app development, networking, and custom technology solutions for businesses of all sizes." 
        />
        <meta 
          name="keywords" 
          content="web development, website design, building websites, Royol Technology, Widmison Francois, digital solutions, custom technology" 
        />
        <meta name="author" content="Widmison Francois" />

        {/* Open Graph Meta */}
        <meta property="og:title" content="Royol Technology | Website Building Experts" />
        <meta 
          property="og:description" 
          content="Build your dream website with Royol Technology. We offer top-notch web development, app development, and networking solutions for businesses." 
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://royoltechnology.com/logo.png" />
        <meta property="og:url" content="https://royoltechnology.com" />

        {/* Twitter Meta */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Royol Technology | Building Websites and More" />
        <meta 
          name="twitter:description" 
          content="Build your website with ease! Royol Technology specializes in web development and custom digital solutions." 
        />
        <meta name="twitter:image" content="https://royoltechnology.com/logo.png" />

        {/* Google Tag */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MWPWZXDV7C"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-MWPWZXDV7C');
          `}
        </script>
      </Helmet>

      <ScrollToTop />
      <Header />

      {/* ✅ All Routes Wrapped Inside `<Routes>` */}
      <Routes>
        <Route 
          path="/" 
          element={
            <>
              <Home />
              <Portfolio />
              <Testimonials />
            </>
          } 
        />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/thank-you" element={<SuccessPage />} />
        <Route path="/payment-canceled" element={<CancelPage />} />
        <Route path="/porfolio" element={<Portfolio />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;