import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Home.css';
import webDevImage from '../assets/web-dev.jpg';
import appDevImage from '../assets/App-dev.png';
import ecomImage from '../assets/E-com.webp';
import { loadStripe } from '@stripe/stripe-js';
import CountUp from 'react-countup';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Home = () => {
  const navigate = useNavigate();
  const [activeDot, setActiveDot] = useState(0);

  const handleScroll = (e) => {
    const container = e.target;
    const cardWidth = container.firstChild.offsetWidth + 20;
    const index = Math.round(container.scrollLeft / cardWidth);
    setActiveDot(index);
  };

  const handleStripeCheckout = async (priceId) => {
    const stripe = await stripePromise;

    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: 'subscription',
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/cancel`,
    });

    if (error) {
      console.error('Stripe Checkout Error:', error.message);
    }
  };

  const services = [
    {
      title: 'Web Development',
      description:
        'We create responsive and modern websites tailored to your business needs, ensuring seamless performance across all devices.',
      image: webDevImage,
    },
    {
      title: 'App Development',
      description:
        'We design and develop mobile applications for iOS and Android platforms, delivering intuitive and feature-rich experiences.',
      image: appDevImage,
    },
    {
      title: 'E-commerce Solutions',
      description:
        'Empower your business with scalable and secure e-commerce platforms, integrated with payment gateways and advanced features.',
      image: ecomImage,
    },
  ];

  const plans = [
    {
      title: 'Starter Basic Website',
      price: '$499.99',
      description:
        'A small website with 1 to 3 pages, including a homepage, about page, and contact page. Basic contact form.',
      features: [
        { label: 'Mobile-responsive design', included: true },
        { label: 'Basic SEO setup', included: true },
        { label: 'Free domain consultation', included: true },
        { label: 'Custom design and development', included: true },
        { label: 'E-commerce capabilities', included: false },
      ],
      delivery: '1-2 weeks',
    },
    {
      title: 'Professional Website',
      price: '$999.99',
      description:
        'A medium-sized website with 1 to 5 pages, including additional pages such as services or product listings.',
      features: [
        { label: 'Mobile-responsive design', included: true },
        { label: 'Basic SEO setup', included: true },
        { label: 'Custom design and development', included: true },
        { label: 'E-commerce capabilities', included: true },
        { label: 'Advanced SEO', included: true },
      ],
      delivery: '2-3 weeks',
    },
    {
      title: 'Enterprise Solutions',
      price: '$9999.99',
      description:
        'A larger website with 1 to 7 pages, offering high-end web design and advanced features like custom integrations.',
      features: [
        { label: 'Mobile-responsive design', included: true },
        { label: 'Custom design and development', included: true },
        { label: 'E-commerce capabilities', included: true },
        { label: 'Advanced SEO', included: true },
        { label: 'API and third-party integrations', included: true },
      ],
      delivery: '4-6 weeks',
    },
  ];

  const maintenancePlans = [
    {
      title: 'Starter Plan',
      price: '$29.99/month',
      stripePriceId: process.env.REACT_APP_STARTER_PLAN_PRICE_ID,
      features: [
        { label: 'Monthly updates and backups', included: true },
        { label: 'Basic performance checks', included: true },
        { label: 'Email support (24-hour response time)', included: true },
        { label: 'Bug fixes', included: false },
        { label: 'Advanced security monitoring', included: false },
      ],
      description: 'Best for personal blogs and small portfolios.',
    },
    {
      title: 'Basic Plan',
      price: '$49.99/month',
      stripePriceId: process.env.REACT_APP_BASIC_PLAN_PRICE_ID,
      features: [
        { label: 'Monthly updates and backups', included: true },
        { label: 'Basic security monitoring', included: true },
        { label: 'Email support', included: true },
        { label: 'Bug fixes (limited to 2 requests/month)', included: true },
        { label: 'Advanced security monitoring', included: false },
      ],
      description: 'Perfect for personal websites and freelancers.',
    },
    {
      title: 'Professional Plan',
      price: '$99.99/month',
      stripePriceId: process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID,
      features: [
        { label: 'Weekly updates and backups', included: true },
        { label: 'Advanced security monitoring', included: true },
        { label: 'Email & chat support', included: true },
        { label: 'Bug fixes (up to 5 requests/month)', included: true },
        { label: 'Custom analytics dashboard', included: false },
      ],
      description: 'Ideal for small-to-medium businesses.',
    },
    {
      title: 'Advanced Plan',
      price: '$149.99/month',
      stripePriceId: process.env.REACT_APP_ADVANCED_PLAN_PRICE_ID,
      features: [
        { label: 'Daily updates and backups', included: true },
        { label: 'Comprehensive security monitoring', included: true },
        { label: 'Priority email & chat support', included: true },
        { label: 'Performance and SEO optimization', included: true },
        { label: 'Custom analytics dashboard', included: false },
      ],
      description: 'Great for e-commerce websites and growing businesses.',
    },
    {
      title: 'Premium Plan',
      price: '$249.99/month',
      stripePriceId: process.env.REACT_APP_PREMIUM_PLAN_PRICE_ID,
      features: [
        { label: 'Daily updates and backups', included: true },
        { label: '24/7 security monitoring', included: true },
        { label: 'Dedicated account manager', included: true },
        { label: 'Custom analytics dashboard', included: true },
        { label: 'Unlimited bug fixes', included: true },
      ],
      description: 'Perfect for enterprises and mission-critical websites.',
    },
    {
      title: 'Enterprise Plan',
      price: 'Custom Quote',
      features: [
        { label: 'Custom updates and integrations', included: true },
        { label: 'API and third-party service management', included: true },
        { label: 'Advanced analytics and performance tracking', included: true },
        { label: 'On-demand development and feature upgrades', included: true },
        { label: 'Dedicated technical team', included: true },
      ],
      description: 'Best for large-scale businesses and organizations.',
    },
  ];

  return (
    <HelmetProvider>
      <Helmet>
        <title>Royol Technology | Web &amp; App Development Services</title>
        <meta
          name="description"
          content="Royol Technology offers cutting-edge web and app development, digital solutions, and IT services to boost your online presence."
        />
      </Helmet>

      {/* Hero Section */}
      <section className="welcome-section-background" id="welcome-section">
        <div className="welcome-container">
          <div className="welcome-text">
            <h1 className="hero-text">
              Boost Revenue 47% Faster with Award-Winning{' '}
              <span className="highlight">Web &amp; Mobile Apps</span>
            </h1>
            <div className="usp-list">
              <div className="usp-item">
                <span className="usp-icon">🏆</span>
                <span>5-Star Rated Development Team</span>
              </div>
              <div className="usp-item">
                <span className="usp-icon">⚡</span>
                <span>Page Speed Optimized (90+ Scores Guaranteed)</span>
              </div>
              <div className="usp-item">
                <span className="usp-icon">🔒</span>
                <span>GDPR &amp; ADA Compliant Solutions</span>
              </div>
            </div>
            <div className="hero-cta-container">
              <button
                className="hero-explore-button"
                onClick={() => navigate('/quote')}
                aria-label="Get free custom quote for web development"
              >
                GET FREE CUSTOM QUOTE
              </button>
              <button
                className="secondary-hero-explore-button"
                onClick={() => navigate('/contact')}
                aria-label="Get free consultation for web development"
              >
                GET FREE CONSULTATION
              </button>
            </div>
          </div>
          <div className="welcome-image">
            <LazyLoadImage
              alt="Mobile app development process showing responsive design and SEO optimization"
              src="img/hero-img-2.png"
              effect="blur"
              width="100%"
              height="auto"
              itemProp="image"
            />
          </div>
        </div>

        <div className="stats-bar" data-aos="fade-up">
          <div className="stat-item">
            <div className="stat-icon">
              <i className="fas fa-award"></i>
            </div>
            <CountUp end={15} duration={3} className="stat-number" suffix="+" />
            <p className="stat-label">Years Experience</p>
            <div className="stat-hover">
              <p>Since 2008</p>
            </div>
          </div>

          <div className="stat-item">
            <div className="stat-icon">
              <i className="fas fa-rocket"></i>
            </div>
            <CountUp end={500} duration={4} className="stat-number" suffix="+" />
            <p className="stat-label">Projects Delivered</p>
            <div className="stat-hover">
              <p>97% Success Rate</p>
            </div>
          </div>

          <div className="stat-item">
            <div className="stat-icon">
              <i className="fas fa-heart"></i>
            </div>
            <CountUp end={98} duration={2} className="stat-number" suffix="%" />
            <p className="stat-label">Client Retention</p>
            <div className="stat-hover">
              <p>5-Year Average</p>
            </div>
          </div>

          <div className="stat-item">
            <div className="stat-icon">
              <i className="fas fa-headset"></i>
            </div>
            <h3 className="stat-number">24/7</h3>
            <p className="stat-label">Support Available</p>
            <div className="stat-hover">
              <p>Average Response Time: 23min</p>
            </div>
          </div>
        </div>
      </section>

      {/* Marketing Hero Section */}
      <section className="marketing-hero" aria-label="Digital Solutions Overview">
        <div className="hero-content">
          <div className="hero-text">
            <Helmet>
              <script type="application/ld+json">
                {`
                {
                  "@context": "https://schema.org",
                  "@type": "ProfessionalService",
                  "name": "Royol Technology",
                  "url": "https://www.royoltechnology.com",
                  "serviceType": "Digital Solutions",
                  "description": "Transform your digital presence with our expert web and app development services.",
                  "makesOffer": [
                    {
                      "@type": "Service",
                      "name": "Web Development",
                      "description": "Responsive, modern websites tailored to your business needs"
                    },
                    {
                      "@type": "Service",
                      "name": "App Development",
                      "description": "iOS and Android applications with intuitive user experiences"
                    }
                  ]
                }
              `}
              </script>
            </Helmet>

            <h1 className="hero-heading">
              Transform Your Digital Presence with{' '}
              <span className="highlight"> Innovative Solutions</span>
            </h1>
            <p className="hero-subheading">
              Drive business growth through cutting-edge technology -{' '}
              <strong>87% of our clients see measurable results within 3 months</strong>
            </p>
            <div className="cta-container">
              <button
                className="cta-primary"
                onClick={() => navigate('/services')}
                aria-label="Explore our services"
              >
                Explore Our Services
                <span className="cta-hover-effect"></span>
              </button>
              <button
                className="cta-secondary"
                onClick={() => navigate('/quote')}
                aria-label="Get a free quote"
              >
                Get Free Quote
                <span className="cta-arrow">→</span>
              </button>
            </div>
          </div>

          {/* Services Grid */}
          <div className="creative-grid">
            {services.map((service, index) => (
              <div
                className={`creative-item item-${index + 1}`}
                key={index}
                itemScope
                itemType="https://schema.org/Service"
              >
                <div
                  className="creative-background"
                  style={{ backgroundImage: `url(${service.image})` }}
                  role="img"
                  aria-label={service.title}
                ></div>
                <div className="creative-content">
                  <h2 className="creative-title" itemProp="name">
                    {service.title}
                  </h2>
                  <p className="creative-description" itemProp="description">
                    {service.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Home;