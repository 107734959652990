import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import emailjs from 'emailjs-com';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Email template data for sending to you (the owner)
    const toOwnerParams = {
      to_name: 'Royol Technology',
      from_name: formData.name,
      from_email: formData.email,
      subject: formData.subject,
      message: `
        Message: ${formData.message}
        From: ${formData.name} (${formData.email})
      `,
    };

    // Email template data for sending to the user
    const toUserParams = {
      to_name: formData.name,
      user_email: formData.email,
      user_message: `We have received your message: "${formData.message}". Thank you for contacting us!`,
    };

    // Send email to you (the owner)
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        toOwnerParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        () => {
          // Send confirmation email to the user
          emailjs
            .send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID,
              process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
              toUserParams,
              process.env.REACT_APP_EMAILJS_USER_ID
            )
            .then(() => {
              setIsSubmitted(true);
              setTimeout(() => {
                setIsSubmitted(false);
                window.location.href = '/';
              }, 3000);
            })
            .catch((error) => {
              console.error('Error sending confirmation email:', error);
              alert('Failed to send confirmation email. Please try again.');
            });
        },
        (error) => {
          console.error('Error sending email to owner:', error);
          alert('Failed to send your message. Please try again.');
        }
      );
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Contact Royol Technology | Get Expert Digital Solutions</title>
        <meta
          name="description"
          content="Connect with our web development experts for custom solutions. Fast response time, 98% client satisfaction. Start your project today!"
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Contact Royol Technology",
            "description": "Digital solutions contact form",
            "url": "https://royoltechnology.com/contact",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+1-239-651-9228",
              "contactType": "customer service",
              "areaServed": "US"
            }
          }`}
        </script>
      </Helmet>

      <section className="contact-modern-hero">
        <div className="contact-modern-container">
          <div className="contact-modern-content">
            <div className="contact-modern-header">
              <h1 className="contact-modern-title">
                Let's Build Your
                <span className="contact-modern-highlight"> Digital Success</span>
              </h1>
              <p className="contact-modern-subtitle">
                24-48 Hour Response Time | 98% Client Satisfaction
              </p>
            </div>

            <div className="contact-modern-grid">
      

              <form className="contact-modern-form" onSubmit={handleSubmit}>
                <div className="form-group-dual">
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name *"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="modern-form-input"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Work Email *"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="modern-form-input"
                  />
                </div>

                <input
                  type="text"
                  name="subject"
                  placeholder="Project Type *"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="modern-form-input"
                />

                <textarea
                  name="message"
                  placeholder="Tell us about your project *"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="modern-form-textarea"
                ></textarea>

                <button type="submit" className="modern-form-submit">
                  Get Free Consultation
                  <span className="submit-arrow">→</span>
                </button>
              </form>
            </div>

            {isSubmitted && (
              <div className="contact-modern-success">
                <div className="success-checkmark">✓</div>
                <h3>Message Received!</h3>
                <p>We'll contact you within 24 hours</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Contact;