import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/white-logo.png';
import './Header.css';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setIsSticky(window.scrollY > 100);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setMenuOpen(false);
    setActiveDropdown(null);
  }, [location]);

  const navItems = [
    { 
      to: '/', 
      label: 'Home',
      icon: 'fas fa-home'
    },
    { 
      label: 'Services',
      subItems: [
        { to: '/services', label: 'Web Development' },
        { to: '/services', label: 'Mobile Apps' },
        { to: '/services', label: 'Cloud Solutions' },
        { to: '/services', label: 'AI Solutions' },
        { to: '/services', label: 'Secutity Systeem Services' },
        { to: '/services', label: 'IT Services & Support' }
      ]
    },
    { 
      label: 'Pricing',
      subItems: [
        { to: '/pricing', label: 'Startup Plan' },
        { to: '/pricing', label: 'Enterprise Plan' },
        { to: '/pricing', label: 'Website maintenance Plan' },
        { to: '/pricing', label: 'Custom Solution' }
      ]
    },
    { to: '/about', label: 'About' },
    { to: '/contact', label: 'Contact' }
  ];

  const renderDesktopNav = () => (
    <nav className="desktop-nav">
      <ul className="nav-list">
        {navItems.map((item) => (
          <li 
            key={item.to || item.label}
            className="nav-item"
            onMouseEnter={() => item.subItems && setActiveDropdown(item.label)}
            onMouseLeave={() => item.subItems && setActiveDropdown(null)}
          >
            {item.to ? (
              <Link to={item.to} className="nav-link">
                {item.label}
              </Link>
            ) : (
              <span className="nav-link">
                {item.label}
                <i className="fas fa-chevron-down ml-2" />
              </span>
            )}

            {item.subItems && activeDropdown === item.label && (
              <div className="dropdown-menu">
                {item.subItems.map((subItem) => (
                  <Link
                    key={subItem.to}
                    to={subItem.to}
                    className="dropdown-item"
                  >
                    {subItem.label}
                  </Link>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );

  const renderMobileMenu = () => (
    <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
      <div className="mobile-menu-header">
        <img src={logo} alt="Logo" className="mobile-logo" />
        <button 
          className="close-button"
          onClick={() => setMenuOpen(false)}
        >
          &times;
        </button>
      </div>
      
      <ul className="mobile-nav-list">
        {navItems.map((item) => (
          <li 
            key={item.to || item.label}
            className="mobile-nav-item"
          >
            {item.subItems ? (
              <div className="mobile-nav-accordion">
                <button 
                  className="accordion-header"
                  onClick={() => setActiveDropdown(
                    activeDropdown === item.label ? null : item.label
                  )}
                >
                  {item.label}
                  <i className={`fas fa-chevron-${activeDropdown === item.label ? 'up' : 'down'}`} />
                </button>
                
                {activeDropdown === item.label && (
                  <div className="accordion-content">
                    {item.subItems.map((subItem) => (
                      <Link
                        key={subItem.to}
                        to={subItem.to}
                        className="mobile-subitem"
                      >
                        {subItem.label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <Link
                to={item.to}
                className="mobile-nav-link"
                onClick={() => setMenuOpen(false)}
              >
                {item.label}
              </Link>
            )}
          </li>
        ))}
      </ul>
      
      <div className="mobile-cta">
        <button 
          className="cta-button"
          onClick={() => {
            navigate('/contact');
            setMenuOpen(false);
          }}
        >
          Free Consultation
        </button>
      </div>
    </div>
  );

  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
      <div className="header-container">
        <Link to="/" className="logo-link">
          <img src={logo} alt="Logo" className="logo" />
        </Link>

        {renderDesktopNav()}

        <div className="header-actions">
          <button 
            className="header-cta-button desktop-only"
            onClick={() => navigate('/contact')}
          >
            Free Consultation
          </button>
          <button 
            className="hamburger"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="hamburger-line" />
            <span className="hamburger-line" />
            <span className="hamburger-line" />
          </button>
        </div>

        {renderMobileMenu()}
      </div>
    </header>
  );
};

export default Header;