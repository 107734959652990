import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CountUp from 'react-countup';
import TeamPhoto from '../assets/website7.png';
import OfficePhoto from '../assets/team2.jpg';
import ClientLogos from '../assets/svg/amazon-web-services-2.svg';
import './About.css';

const About = () => {
  const navigate = useNavigate();

  return (
    <div className="modern-about-root">
      <Helmet>
        <title>About Royol Technology - Digital Innovation Leaders Since 2008</title>
        <meta name="description" content="Royol Technology: Award-winning web development agency helping businesses grow through cutting-edge digital solutions. 500+ successful projects delivered." />
      </Helmet>

     {/* Hero Section */}
<section className="modern-about-hero">
  <div className="modern-hero-container">
    <div className="modern-hero-content">
      <div className="modern-hero-glow"></div>
      
      <h1 className="modern-hero-headline">
        <span className="modern-hero-gradient">Digital Innovation</span>
        <span className="modern-hero-subtext">
          Pioneering Tech Solutions Since 
          <CountUp end={2008} duration={3} className="modern-hero-year" />
        </span>
      </h1>

      <div className="modern-hero-stats">
        <div className="modern-stat-card" data-aos="fade-up">
          <CountUp end={500} duration={2} prefix="+" className="modern-stat-number" />
          <p className="modern-stat-label">Successful Launches</p>
        </div>
        
        <div className="modern-stat-card" data-aos="fade-up" data-aos-delay="150">
          <CountUp end={98} duration={2.5} suffix="%" className="modern-stat-number" />
          <p className="modern-stat-label">Client Retention</p>
        </div>
      </div>

      <button 
        className="modern-cta-primary"
        onClick={() => navigate('/contact')}
        data-aos="fade-up"
        data-aos-delay="300"
      >
        Start Your Digital Transformation
        <span className="cta-aura"></span>
      </button>

      <div className="modern-client-wall">
        <div className="client-scroll">
          {['Fortune 500', 'Startups', 'E-commerce Leaders', 'Tech Innovators'].map((client, i) => (
            <span key={i} className="client-badge">{client}</span>
          ))}
        </div>
      </div>
    </div>
  </div>
</section>

      {/* Value Proposition */}
      <section className="modern-value-grid">
        <div className="modern-value-card">
          <div className="modern-value-icon">🚀</div>
          <h3>Accelerated Growth</h3>
          <p>Average 47% revenue increase for clients within first year</p>
        </div>
        <div className="modern-value-card">
          <div className="modern-value-icon">🛡️</div>
          <h3>Enterprise Security</h3>
          <p>Military-grade protection for all digital assets</p>
        </div>
        <div className="modern-value-card">
          <div className="modern-value-icon">📈</div>
          <h3>ROI-Focused</h3>
          <p>Performance-based strategies that deliver results</p>
        </div>
      </section>

      {/* Leadership Section */}
      <section className="modern-leadership-section">
        <div className="modern-leadership-container">
          <div className="modern-leadership-profile">
            <LazyLoadImage
              src={OfficePhoto}
              alt="CEO Widmison Francois leading team"
              effect="blur"
              className="modern-leader-image"
            />
            <div className="modern-leader-bio">
              <h2>Widmison Francois</h2>
              <p className="modern-leader-title">Founder & CEO</p>
              <p className="modern-leader-quote">
                "Our mission is to transform businesses through innovative technology solutions 
                that drive real results."
              </p>
              <button 
                className="modern-cta-secondary"
                onClick={() => navigate('/contact')}
              >
                Meet Our Team
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Hidden SEO Content */}
      <div className="modern-seo-content" aria-hidden="true">
        <h2>Web Development Company West Palm Beach</h2>
        <p>
          Royol Technology is a premier web development agency based in West Palm Beach, Florida, 
          specializing in custom website design, e-commerce solutions, and mobile app development. 
          Serving clients nationwide since 2008.
        </p>
      </div>
    </div>
  );
};

export default About;