import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { loadStripe } from '@stripe/stripe-js'; // Added missing import
import applepay from '../assets/svg/apple-pay-2.svg';
import stripe from '../assets/svg/stripe-4.svg';
import googlePartner from '../assets/svg/google-1-1.svg';
import './Pricing.css';
import './MaintenancePlan.css';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Pricing = () => {
  const navigate = useNavigate();
  const [activeDot, setActiveDot] = useState(0);
  const cardCount = 6;

  const handleScroll = (e) => {
    const container = e.target;
    const cardWidth = container.firstChild.offsetWidth + 20;
    const index = Math.round(container.scrollLeft / cardWidth);
    setActiveDot(index);
  };

  const handleStripeCheckout = async (priceId) => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: 'subscription',
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/cancel`,
    });

    if (error) console.error('Stripe Checkout Error:', error.message);
  };

  const webPlans = [
    {
      title: "Starter Basic Website",
      price: "$499.99",
      description: "A small website with 1 to 3 pages, including a homepage, about page, and contact page. Basic contact form.",
      features: [
        { label: "Mobile-responsive design", included: true },
        { label: "Basic SEO setup", included: true },
        { label: "Free domain consultation", included: true },
        { label: "Custom design and development", included: false },
        { label: "E-commerce capabilities", included: false },
      ],
      delivery: "1-2 weeks",
    },
    {
      title: "Professional Website",
      price: "$999.99",
      description: "A medium-sized website with 1 to 5 pages, including additional pages such as services or product listings.",
      features: [
        { label: "Mobile-responsive design", included: true },
        { label: "Basic SEO setup", included: true },
        { label: "Custom design and development", included: true },
        { label: "E-commerce capabilities", included: true },
        { label: "Advanced SEO", included: true },
      ],
      delivery: "2-3 weeks",
    },
    {
      title: "Enterprise Solutions",
      price: "$9999.99",
      description: "A larger website with 1 to 7 pages, offering high-end web design and advanced features like custom integrations.",
      features: [
        { label: "Mobile-responsive design", included: true },
        { label: "Custom design and development", included: true },
        { label: "E-commerce capabilities", included: true },
        { label: "Advanced SEO", included: true },
        { label: "API and third-party integrations", included: true },
      ],
      delivery: "4-6 weeks",
    },
  ];
  const faqs = [
    {
      question: "What's included in the basic SEO setup?",
      answer: "Our basic SEO includes meta tags optimization, sitemap creation, and Google Search Console integration."
    },
    {
      question: "Can I upgrade my plan later?",
      answer: "Yes, you can easily upgrade your plan at any time as your business grows."
    },
    {
      question: "Do you offer ongoing maintenance?",
      answer: "We offer optional maintenance packages with monthly updates and security checks."
    }
  ];

  const maintenancePlans = [
    {
      title: 'Starter Plan',
      price: '$29.99/month',
      stripePriceId: process.env.REACT_APP_STARTER_PLAN_PRICE_ID,
      features: [
        { label: 'Monthly updates and backups', included: true },
        { label: 'Basic performance checks', included: true },
        { label: 'Email support (24-hour response time)', included: true },
        { label: 'Bug fixes', included: false },
        { label: 'Advanced security monitoring', included: false },
      ],
      description: 'Best for personal blogs and small portfolios.',
    },
    {
      title: 'Basic Plan',
      price: '$49.99/month',
      stripePriceId: process.env.REACT_APP_BASIC_PLAN_PRICE_ID,
      features: [
        { label: 'Monthly updates and backups', included: true },
        { label: 'Basic security monitoring', included: true },
        { label: 'Email support', included: true },
        { label: 'Bug fixes (limited to 2 requests/month)', included: true },
        { label: 'Advanced security monitoring', included: false },
      ],
      description: 'Perfect for personal websites and freelancers.',
    },
    {
      title: 'Professional Plan',
      price: '$99.99/month',
      stripePriceId: process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID,
      features: [
        { label: 'Weekly updates and backups', included: true },
        { label: 'Advanced security monitoring', included: true },
        { label: 'Email & chat support', included: true },
        { label: 'Bug fixes (up to 5 requests/month)', included: true },
        { label: 'Custom analytics dashboard', included: false },
      ],
      description: 'Ideal for small-to-medium businesses.',
    },
    {
      title: 'Advanced Plan',
      price: '$149.99/month',
      stripePriceId: process.env.REACT_APP_ADVANCED_PLAN_PRICE_ID,
      features: [
        { label: 'Daily updates and backups', included: true },
        { label: 'Comprehensive security monitoring', included: true },
        { label: 'Priority email & chat support', included: true },
        { label: 'Performance and SEO optimization', included: true },
        { label: 'Custom analytics dashboard', included: false },
      ],
      description: 'Great for e-commerce websites and growing businesses.',
    },
    {
      title: 'Premium Plan',
      price: '$249.99/month',
      stripePriceId: process.env.REACT_APP_PREMIUM_PLAN_PRICE_ID,
      features: [
        { label: 'Daily updates and backups', included: true },
        { label: '24/7 security monitoring', included: true },
        { label: 'Dedicated account manager', included: true },
        { label: 'Custom analytics dashboard', included: true },
        { label: 'Unlimited bug fixes', included: true },
      ],
      description: 'Perfect for enterprises and mission-critical websites.',
    },
    {
      title: 'Enterprise Plan',
      price: 'Custom Quote',
      features: [
        { label: 'Custom updates and integrations', included: true },
        { label: 'API and third-party service management', included: true },
        { label: 'Advanced analytics and performance tracking', included: true },
        { label: 'On-demand development and feature upgrades', included: true },
        { label: 'Dedicated technical team', included: true },
      ],
      description: 'Best for large-scale businesses and organizations.',
    },
  ];


  return (
    <HelmetProvider>
    <Helmet>
      <title>Affordable Pricing Plans for Web and App Development | Royol Technology</title>
      <meta
        name="description"
        content="Compare our web development pricing plans with detailed feature breakdowns. Professional packages with SEO optimization, e-commerce solutions, and custom integrations."
      />
      <meta name="keywords" content="web development pricing, compare plans, SEO packages, e-commerce solutions, custom website costs" />
    </Helmet>

    <section className="pricing-hero-section">
  <div className="pricing-hero-content">
    <h1>
      <span className="pricin-gradient-text">Smart Investment,</span><br />
      Extraordinary Results
    </h1>
    
    <p className="pricing-hero-subtitle">
      Enterprise-grade web solutions with 
      <span className="pricing-highlight"> 24/7 premium support </span> 
      and 
      <span className="pricing-highlight"> 14-day risk-free trial</span>
    </p>

    <div className="pricing-value-propositions">
      <div className="pricing-prop-card">
        <div className="pricing-prop-icon security"></div>
        <h3>Military-Grade Security</h3>
        <p>SSL/TLS encryption + daily malware scans</p>
      </div>
      
      <div className="pricing-prop-card">
        <div className="pricing-prop-icon performance"></div>
        <h3>Lightning Performance</h3>
        <p>99.9% uptime & Google Core Vitals optimized</p>
      </div>
      
      <div className="pricing-prop-card">
        <div className="pricing-prop-icon support"></div>
        <h3>Priority Support</h3>
        <p>Dedicated team with 2-hour response time</p>
      </div>
    </div>
  </div>
</section>
    
    <section className="pricing-section" id="pricing-section">
      <h1 className="section-title">Affordable and Transparent Pricing</h1>
      <p className="pricing-section-subtitle">
        Invest in quality web solutions with our risk-free 14-day money back guarantee
      </p>
      <div className="pricing-table">
        {webPlans.map((plan, index) => (
          <div className="pricing-card" key={index}>
            <h2>{plan.title}</h2>
            <p className="price">{plan.price}</p>
            <p className="description">{plan.description}</p>
            <table className="features-table">
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Included</th>
                </tr>
              </thead>
              <tbody>
                {plan.features.map((feature, featureIndex) => (
                  <tr key={featureIndex}>
                    <td>{feature.label}</td>
                    <td>
                      {feature.included ? (
                        <span className="checkmark">✔</span>
                      ) : (
                        <span className="cross">✖</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className="delivery-time">Delivery time: {plan.delivery}</p>
            <button
              className="get-started-button"
              onClick={() => navigate(`/quote?plan=${encodeURIComponent(plan.title)}`)}
            >
              Get Started
            </button>
          </div>
        ))}
      </div>

      <section className="maintenance-plan-section" id="maintenance-plans">
        <h1 className="plan-title">Website Maintenance Plans</h1>
        <p className="plan-subtitle">
          Keep your website running smoothly with our maintenance subscriptions
        </p>
        <div className="plan-cards-container" onScroll={handleScroll}>
          {maintenancePlans.map((plan, index) => (
            <div className="plan-card" key={index}>
              <h2>{plan.title}</h2>
              <p className="plan-price">{plan.price}</p>
              <table className="features-table">
                <tbody>
                  {plan.features.map((feature, idx) => (
                    <tr key={idx}>
                      <td>{feature.label}</td>
                      <td>
                        {feature.included ? (
                          <span className="checkmark">✔</span>
                        ) : (
                          <span className="cross">✖</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p>{plan.description}</p>
              {plan.price === 'Custom Quote' ? (
                <button className="subscribe-button" onClick={() => navigate('/contactform')}>
                  Contact Us
                </button>
              ) : (
                <button
                  className="subscribe-button"
                  onClick={() => handleStripeCheckout(plan.stripePriceId)}
                >
                  Subscribe
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="scroll-indicators">
          {Array.from({ length: cardCount }).map((_, index) => (
            <div
              key={index}
              className={`dot ${activeDot === index ? 'active' : ''}`}
              onClick={() => {
                document.querySelector('.plan-cards-container').scrollTo({
                  left: index * (300 + 20),
                  behavior: 'smooth',
                });
              }}
            ></div>
          ))}
        </div>
      </section>

      <div className="pricing-faq-section">
        <h2 className="section-title">Frequently Asked Questions</h2>
        <div className="faq-grid">
          {faqs.map((faq, index) => (
            <div className="faq-card" key={index}>
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="final-cta">
        <h2>Ready to Transform Your Digital Presence?</h2>
        <p>Schedule a free consultation with our experts to find your perfect solution</p>
        <button 
          className="cta-button"
          onClick={() => navigate('/contact')}
        >
          Book Free Consultation
        </button>
        <div className="trust-badges">
          <div className="trust-item">✓ 14-Day Money Back Guarantee</div>
          <div className="trust-item">✓ 24/7 Priority Support</div>
          <div className="trust-item">✓ SSL Security Included</div>
        </div>
      </div>
    </section>
  </HelmetProvider>
  );
};

export default Pricing;