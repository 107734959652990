import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
import './Quote.css';

const Quote = () => {
  const [step, setStep] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    service: '',
    projectDescription: '',
    websiteType: '',
    platform: '',
    networkType: '',
  });
  const [captchaToken, setCaptchaToken] = useState('');

  const servicePrices = {
    webDevelopment: { base: 2000, types: { eCommerce: 1000, corporate: 800, blog: 500 } },
    appDevelopment: { base: 5000, platforms: { iOS: 1500, android: 1500, crossPlatform: 2000 } },
    networkingSolutions: { base: 3000, types: { lan: 500, wan: 800, cloudNetworking: 1200 } }
  };

  const handleNext = () => setStep(prev => Math.min(prev + 1, 3));
  const handlePrev = () => setStep(prev => Math.max(prev - 1, 1));

  const updatePrice = (type, value, price) => {
    setFormData(prev => ({ ...prev, [type]: value }));
    setTotalPrice(prev => {
      const basePrice = servicePrices[formData.service]?.base || 0;
      return basePrice + (price || 0);
    });
  };

  const handlePaymentRedirect = async () => {
    if (!captchaToken) return alert('Please complete security verification');
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/create-checkout-session`,
        { totalPrice, formData, captchaToken }
      );
      response.data.url ? window.location = response.data.url : alert('Payment error');
    } catch (error) {
      console.error('Payment Error:', error);
      alert('Payment failed - please try again');
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Instant Quote | Custom Web & App Development Pricing | Royol Technology</title>
        <meta
          name="description"
          content="Get instant pricing for your digital project. 98% client satisfaction. Secure quote process with 24-hour response guarantee."
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Web Development",
            "provider": {
              "@type": "Organization",
              "name": "Royol Technology"
            },
            "areaServed": "United States",
            "offers": {
              "@type": "Offer",
              "priceSpecification": {
                "price": "${totalPrice}",
                "priceCurrency": "USD"
              }
            }
          }`}
        </script>
      </Helmet>

      <section className="modern-quote-section">
        <div className="quote-progress" style={{ '--progress': `${(step-1)*33}%` }}>
          <div className="progress-bar"></div>
          <div className="step-dots">
            {[1,2,3].map(s => <div key={s} className={`dot ${step >= s ? 'active' : ''}`} />)}
          </div>
        </div>

        <div className="quote-container">
          <div className="quote-header">
            <h1>Get Your Instant Project Estimate</h1>
            <p>3-step process • 24-hour response • 100% confidential</p>
          </div>

          <div className="quote-steps">
            {/* Step 1 - Contact Info */}
            {step === 1 && (
              <div className="quote-step active">
                <div className="form-grid">
                  <div className="input-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label>Work Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => setFormData({...formData, email: e.target.value})}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label>Phone</label>
                    <input
                      type="tel"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={(e) => setFormData({...formData, phoneNumber: e.target.value})}
                      required
                    />
                  </div>
                </div>
                <button className="next-btn" onClick={handleNext}>
                  Continue to Services ➔
                </button>
              </div>
            )}

            {/* Step 2 - Service Selection */}
            {step === 2 && (
              <div className="quote-step active">
                <div className="service-cards">
                  <div 
                    className={`service-card ${formData.service === 'webDevelopment' ? 'selected' : ''}`}
                    onClick={() => updatePrice('service', 'webDevelopment', servicePrices.webDevelopment.base)}
                  >
                    <h3>Web Development</h3>
                    <p>From ${servicePrices.webDevelopment.base}</p>
                    <div className="card-features">
                    </div>
                  </div>

                  {/* Add similar cards for other services */}
                </div>

                {formData.service === 'webDevelopment' && (
                  <div className="service-options">
                    <h4>Website Type</h4>
                    <div className="option-grid">
                      {Object.entries(servicePrices.webDevelopment.types).map(([type, price]) => (
                        <div 
                          key={type}
                          className={`option-card ${formData.websiteType === type ? 'selected' : ''}`}
                          onClick={() => updatePrice('websiteType', type, price)}
                        >
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                          <span>+${price}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="form-actions">
                  <button className="back-btn" onClick={handlePrev}>← Back</button>
                  <button className="next-btn" onClick={handleNext}>
                    Continue to Summary ➔
                  </button>
                </div>
              </div>
            )}

            {/* Step 3 - Payment */}
            {step === 3 && (
              <div className="quote-step active">
                <div className="price-summary">
                  <h3>Your Project Estimate</h3>
                  <div className="price-display">
                    <span className="total">${totalPrice}</span>
                    <span className="disclaimer">+ applicable taxes</span>
                  </div>
                  <div className="security-badges">
                    <LazyLoadImage src="ssl-badge.png" alt="SSL Secure" effect="blur" />
                    <LazyLoadImage src="stripe-badge.png" alt="Stripe Certified" effect="blur" />
                  </div>
                </div>

                <HCaptcha
                  sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                  onVerify={setCaptchaToken}
                  theme="dark"
                />

                <button 
                  className="payment-btn"
                  onClick={handlePaymentRedirect}
                  disabled={!captchaToken}
                >
                  Secure Checkout ➔
                </button>

                <div className="money-back">
                  <span>🔒 100% Money-Back Guarantee</span>
                  <span>📞 24/7 Support</span>
                </div>
              </div>
            )}
          </div>

          <div className="quote-sidebar">
            <div className="trust-card">
              <h4>Why Choose Us?</h4>
              <ul>
                <li>✅ 15+ Years Experience</li>
                <li>✅ 500+ Successful Projects</li>
                <li>✅ 98% Client Satisfaction</li>
              </ul>
            </div>
            <div className="client-testimonial">
              <p>"Royol delivered exactly what we needed, on time and on budget!"</p>
              <span>- Satisfied Client</span>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Quote;