import React from 'react';
import { Helmet } from 'react-helmet';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <>
      <Helmet>
        <title>Royol Technology</title>
        <meta
          name="description"
          content="Connect with Royol Technology for Web Development, App Development, Networking, and Security Systems. Serving Florida and offering global online services."
        />
      </Helmet>
      <footer>
        <div className="footer-main">
          {/* Services Section */}
          <div className="footer-section">
            <h4>Services</h4>
            <ul>
              <li><a href="#web-development">Web Development</a></li>
              <li><a href="#app-development">App Development</a></li>
              <li><a href="#networking">Networking Solutions</a></li>
              <li><a href="#security">Security Systems</a></li>
              <li><a href="#consulting">IT Consulting</a></li>
            </ul>
          </div>

          {/* Service Areas Section */}
          <div className="footer-section">
            <h4>Service Areas</h4>
            <div className="cities-container">
              <ul>
                <li><a href="/services/florida/miami">Miami, FL</a></li>
                <li><a href="/services/florida/orlando">Orlando, FL</a></li>
                <li><a href="/services/florida/tampa">Tampa, FL</a></li>
                <li><a href="/services/florida/jacksonville">Jacksonville, FL</a></li>
                <li><a href="/services/florida/fort-lauderdale">Fort Lauderdale, FL</a></li>
                <li><a href="/services/florida/west-palm-beach">West Palm Beach, FL</a></li>
                <li><a href="/services/florida/tallahassee">Tallahassee, FL</a></li>
                <li><a href="/services/florida/naples">Naples, FL</a></li>
                <li><a href="/services/florida/sarasota">Sarasota, FL</a></li>
                <li><a href="/services/florida/daytona-beach">Daytona Beach, FL</a></li>
                <li><a href="/services/florida/melbourne">Melbourne, FL</a></li>
                <li><a href="/services/florida/gainesville">Gainesville, FL</a></li>
                <li><a href="/services/florida/pensacola">Pensacola, FL</a></li>
                <li><a href="/services/florida/destin">Destin, FL</a></li>
                <li><a href="/services/florida/clearwater">Clearwater, FL</a></li>
                <li><a href="/services/florida/boca-raton">Boca Raton, FL</a></li>
              </ul>
            </div>
            <div className="online-services">
              <a href="/online-services">
                <FontAwesomeIcon icon={faGlobe} /> Global Online Services
              </a>
            </div>
            <a href="/services" className="view-all-link">View All Florida Locations</a>
          </div>

          {/* Contact Section */}
          <div className="footer-section">
            <h4>Contact</h4>
            <p>3900 Haverhill Rd N, #223507, West Palm Beach, FL 33422</p>
            <p>Phone: 239-651-9228</p>
            <p>
              Email:{' '}
              <a href="mailto:info@royoltechnology.com">
                info@royoltechnology.com
              </a>
            </p>
            <div className="virtual-office">
              <h5>Virtual Office Available</h5>
              <p>Remote Support & Consultations Worldwide</p>
            </div>
          </div>

          {/* Follow Us Section */}
          <div className="footer-section">
            <h4>Connect</h4>
            <div className="social-links">
              <a href="https://www.linkedin.com/company/royol-technology" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="https://www.facebook.com/royoltechnology" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/royoltechnology" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://twitter.com/royoltech" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
            <div className="newsletter">
              <h5>Tech Insights Newsletter</h5>
              <form>
                <input type="email" placeholder="Enter your email" />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="footer-bottom">
          <p>&copy; 2025 Royol Technology LLC. All rights reserved.</p>
          <div className="legal-links">
            <a href="/terms">Terms of Service</a>
            <a href="/privacy">Privacy Policy</a>
            <a href="/accessibility">Accessibility</a>
            <a href="/sitemap">Sitemap</a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;