import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './Services.css';

// Import Service Images
import webDevImage from '../assets/web-dev.jpg';
import appDevImage from '../assets/App-dev.png';
import ecomImage from '../assets/E-com.webp';
import cloudImage from '../assets/cloud-services.jpg';
import erpImage from '../assets/erp.jpg';
import digitalMarketingImage from '../assets/digital-marketing.jpg';
import securityImage from '../assets/security.jpg';
import itSupportImage from '../assets/it-support.jpg';
import aiSolutionImage from '../assets/ai-solutions.jpg';

// Services Data
const services = [
  { title: 'Full-Stack Web Development', description: 'SEO-optimized web development using React and Node.js.', image: webDevImage },
  { title: 'Mobile App Development', description: 'iOS and Android applications with push notifications.', image: appDevImage },
  { title: 'E-commerce Solutions', description: 'Custom online stores with secure payment gateways.', image: ecomImage },
  { title: 'Cloud Services', description: 'AWS, Azure, and GCP solutions for secure cloud infrastructure.', image: cloudImage },
  { title: 'ERP & CRM', description: 'Custom ERP/CRM software tailored to business needs.', image: erpImage },
  { title: 'Digital Marketing', description: 'SEO, PPC, and social media growth strategies.', image: digitalMarketingImage },
  { title: 'Security System Services', description: 'Advanced surveillance and alarm system installations.', image: securityImage },
  { title: 'IT Services & Support', description: 'Network management, IT infrastructure, and technical support.', image: itSupportImage },
  { title: 'AI Solutions', description: 'Custom AI-driven solutions including automation, chatbots, and predictive analytics.', image: aiSolutionImage },
];

const techStack = [
  { name: 'AWS', logo: 'aws-logo.png' },
  { name: 'Google Cloud', logo: 'gcp-logo.png' },
  { name: 'React', logo: 'react-logo.png' },
  // Add more tech stack items
];

// FAQ Data
const faqs = [
  { question: 'How long does development take?', answer: 'Typically 4-8 weeks depending on complexity.' },
  { question: 'What technologies do you use?', answer: 'We work with React, Node.js, AWS, and various modern frameworks.' },
];

const Services = () => {
  const navigate = useNavigate();
  const [activeFAQ, setActiveFAQ] = useState(null);

  return (
    <div className="services-page">
      {/* SEO Metadata */}
      <Helmet>
        <title>Digital Solutions Services | Royol Technology</title>
        <meta name="description" content="Comprehensive digital solutions including web development, mobile apps, and AI integration. See our 95% client satisfaction rate." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "Royol Technology",
              "serviceType": "Digital Solutions",
              "description": "Transform your digital presence with expert web and app development services",
              "makesOffer": [
                { "@type": "Service", "name": "Web Development", "description": "Modern, responsive websites tailored to business needs." },
                { "@type": "Service", "name": "App Development", "description": "iOS and Android apps with intuitive UI/UX." }
              ]
            }
          `}
        </script>
      </Helmet>

      {/* Hero Section */}
      <section className="marketing-hero" aria-label="Digital Solutions Overview">
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="hero-heading">
              Transform Your Digital Presence with
              <span className="highlight"> Innovative Solutions</span>
            </h1>
            <p className="hero-subheading">
              Drive business growth through cutting-edge technology -
              <strong> 87% of our clients see measurable results within 3 months</strong>
            </p>

            <div className="cta-container">
              <button className="cta-primary" onClick={() => navigate('/services')} aria-label="Explore our services">
                Explore Our Services
              </button>
              <button className="cta-secondary" onClick={() => navigate('/quote')} aria-label="Get a free quote">
                Get Free Quote →
              </button>
            </div>
          </div>
        </div>
      


    {/* Services Grid */}
    <div className="creative-grid">
      {services.map((service, index) => (
        <div 
          className={`creative-item item-${index + 1}`} 
          key={index}
          itemScope
          itemType="https://schema.org/Service"
        >
          <div 
            className="creative-background" 
            style={{ backgroundImage: `url(${service.image})` }}
            role="img"
            aria-label={service.title}
          ></div>
          <div className="creative-content">
            <h2 className="creative-title" itemProp="name">{service.title}</h2>
            <p className="creative-description" itemProp="description">
              {service.description}
            </p>
          </div>
        </div>
      ))}
    </div>
    </section>
 

      {/* Marketing Showcase */}
      <section className="services-marketing-showcase">
        <div className="services-container">
          <h2>Results-Driven Digital Marketing</h2>
          <div className="services-results-grid">
            <div className="services-result-card">
              <CountUp end={250} suffix="%" duration={2.5} className="result-number" />
              <p>Average ROI Increase</p>
            </div>
            <div className="services-result-card">
              <CountUp end={150} suffix="%" duration={2.5} className="result-number" />
              <p>Social Media Growth</p>
            </div>
          </div>
        </div>
      </section>


      {/* Why Choose Us Section */}
<section className="why-choose-us">
  <div className="choose-container">
    <h2 className="section-title">Why Choose Us?</h2>
    <p className="choose-subtext">We provide high-quality digital solutions that drive real results.</p>

    <div className="choose-grid">
      <div className="choose-card">
        <div className="choose-icon">🚀</div>
        <h3>Innovative Solutions</h3>
        <p>We leverage cutting-edge technology to create modern, scalable digital solutions.</p>
      </div>

      <div className="choose-card">
        <div className="choose-icon">💡</div>
        <h3>Expert Team</h3>
        <p>Our experienced developers and designers bring your ideas to life with precision.</p>
      </div>

      <div className="choose-card">
        <div className="choose-icon">📈</div>
        <h3>Proven Results</h3>
        <p>We have a track record of delivering projects that boost engagement and revenue.</p>
      </div>

      <div className="choose-card">
        <div className="choose-icon">🔒</div>
        <h3>Security First</h3>
        <p>We ensure robust security in all our solutions, keeping your data safe.</p>
      </div>
    </div>
  </div>
</section>

      {/* FAQ Section */}
      <section className="services-faq-section">
        <div className="services-container">
          <h2>Frequently Asked Questions</h2>
          <div className="services-faq-grid">
            {faqs.map((faq, index) => (
              <div
                className={`services-faq-card ${activeFAQ === index ? 'active' : ''}`}
                key={index}
                onClick={() => setActiveFAQ(activeFAQ === index ? null : index)}
              >
                <div className="services-faq-question">
                  <h4>{faq.question}</h4>
                  <span className="services-toggle-icon">{activeFAQ === index ? '-' : '+'}</span>
                </div>
                {activeFAQ === index && <div className="services-faq-answer">{faq.answer}</div>}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;