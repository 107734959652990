import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Testimonials.css';
import avatar1 from '../assets/avatars/avatar1.jpg';
import avatar2 from '../assets/avatars/avatar2.jpg';
import avatar3 from '../assets/avatars/avatar3.jpg';
import company1 from '../assets/logos/GJPPRO-LOGO.png';
import company2 from '../assets/logos/company2.png';
import company3 from '../assets/logos/company3.png';


const Testimonials = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out-cubic'
    });

    // Enhanced reviews data with more marketing-focused content
    const reviewsData = [
      {
        id: 1,
        review: "Royol Technology transformed our digital presence completely. Our mobile app saw a 300% increase in user engagement post-launch!",
        name: "Jean Pierre",
        role: "CEO at JGPPro",
        rating: 5,

      },
      {
        id: 2,
        review: "Their SEO strategy boosted our organic traffic by 150% in just 6 months. Exceptional results and constant communication!",
        name: "Michael Chen",
        role: "Marketing Director",
        rating: 5,
      },
      {
        id: 3,
        review: "The web platform they developed increased our conversion rate by 80%. User experience is absolutely flawless!",
        name: "Emma Wilson",
        role: "Product Manager",
        rating: 5,
      }
    ];
    setReviews(reviewsData);
  }, []);

  return (
    <>
      <Helmet>
        <title>Client Success Stories & Reviews | Royol Technology - Trusted Digital Partner</title>
        <meta
          name="description"
          content="Discover why industry leaders choose Royol Technology. Read verified client testimonials showcasing our expertise in web development, SEO, and digital transformation."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ItemList",
              "itemListElement": [
                ${reviews.map((review, index) => `
                  {
                    "@type": "ListItem",
                    "position": ${index + 1},
                    "item": {
                      "@type": "Review",
                      "author": "${review.name}",
                      "reviewBody": "${review.review}",
                      "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "${review.rating}"
                      }
                    }
                  }`).join(',')}
              ]
            }
          `}
        </script>
      </Helmet>

      <section className="testimonials-section" id="success-stories">
        <div className="testimonials-header">
          <h1 className="testimonials-title">
            Trusted by Industry Leaders
            <span className="rating-badge">
              <span className="rating-stars">★★★★★</span>
              4.9/5 Average Rating
            </span>
          </h1>
          <p className="testimonials-subtitle">Join 500+ satisfied companies accelerating their digital growth</p>
        </div>

        <div className="testimonials-container">
          <div className="reviews-grid">
            {reviews.map((review, index) => (
              <article 
                className="review-card"
                key={review.id}
                data-aos="zoom-in-up"
                data-aos-delay={index * 150}
              >
                <div className="review-meta">
                  <div className="author-info">
                    <div>
                      <h3 className="review-author">{review.name}</h3>
                      <p className="author-role">{review.role}</p>
                    </div>
                    <div className="rating">
                    {"★".repeat(review.rating)}
                  </div>
                  </div>
                </div>
                <p className="review-text">"{review.review}"</p>
                <div className="review-footer">

                </div>
              </article>
            ))}
          </div>

        </div>
      </section>
    </>
  );
};

export default Testimonials;