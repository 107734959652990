import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import './Portfolio.css';

const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [visibleItems, setVisibleItems] = useState(6);

  const portfolioItems = [
    {
      id: 1,
      title: 'E-commerce Platform',
      category: 'websites',
      image: 'img/website4.png',
      link: 'https://gjppro.com',
      client: "Caribbe-n store",
      results: "300% increase in conversion rate",
      tech: ['React', 'Node.js', 'AWS'],
      clientLogo: 'gjp-logo.png'
    },
    {
      id: 2,
      title: 'Service Industry Portal',
      category: 'websites',
      image: 'img/website6.png',
      link: 'https://gjppro.com',
      client: "HKT Multiservices",
      results: "40% reduction in support tickets",
      tech: ['WordPress', 'WooCommerce', 'PHP'],
      clientLogo: 'hkt-logo.png'
    },
    // Add other items with similar structure
  ];

  const filteredItems = selectedCategory === 'all' 
    ? portfolioItems 
    : portfolioItems.filter(item => item.category === selectedCategory);

  const loadMore = () => setVisibleItems(prev => prev + 6);

  return (
    <>
      <Helmet>
        <title>Digital Portfolio | Royol Technology - Award-Winning Web & App Development</title>
        <meta
          name="description"
          content="Explore Royol Technology's portfolio of successful web and mobile app projects. See how we've helped businesses achieve digital transformation."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ItemList",
              "itemListElement": [
                ${portfolioItems.slice(0, visibleItems).map((item, index) => `
                  {
                    "@type": "ListItem",
                    "position": ${index + 1},
                    "item": {
                      "@type": "CreativeWork",
                      "name": "${item.title}",
                      "url": "${item.link}",
                      "description": "Developed for ${item.client} with ${item.tech.join(', ')}",
                      "keywords": "${item.tech.join(', ')}"
                    }
                  }`).join(',')}
              ]
            }
          `}
        </script>
      </Helmet>

      <section className="portfolio-section" id="portfolio">
        <div className="portfolio-header">
          <h2 className="section-title">
            Our Digital Masterpieces
            <span className="stats-badge">50+ Successful Projects Delivered</span>
          </h2>
          <p className="section-subtitle">Transforming ideas into high-performing digital solutions</p>
        </div>

       

        <div className="portfolio-grid">
          <AnimatePresence>
            {filteredItems.slice(0, visibleItems).map((item) => (
              <motion.article 
                key={item.id}
                className="portfolio-card"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.4 }}
                layout
              >
                <div className="card-inner">
                  <img 
                    src={item.image} 
                    alt={`${item.client} project screenshot`} 
                    loading="lazy"
                    className="project-image"
                  />
                  <div className="card-overlay">
                    <div className="client-header">
                      <div className="client-info">
                        <h3>{item.client}</h3>

                      </div>
                    </div>
                    
                    <div className="project-details">
                      <p className="project-results">
                        <strong>Results:</strong> {item.results}
                      </p>
                      <motion.a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="case-study-link"
                        whileHover={{ x: 5 }}
                      >
                        View Case Study
                        <svg aria-hidden="true" viewBox="0 0 24 24" width="16">
                          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
                        </svg>
                      </motion.a>
                    </div>
                  </div>
                </div>
              </motion.article>
            ))}
          </AnimatePresence>
        </div>

        {visibleItems < filteredItems.length && (
          <motion.button 
            className="load-more"
            onClick={loadMore}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Show More Projects
            <span className="count-badge">{filteredItems.length - visibleItems}</span>
          </motion.button>
        )}

        <div className="portfolio-footer">
          <p className="cta-text">Ready to start your project?</p>
          <div className="cta-buttons">
            <motion.a 
              href="/contact"
              className="cta-primary"
              whileHover={{ y: -2 }}
            >
              Schedule Free Consultation
            </motion.a>
            <motion.a 
              href="/portfolio"
              className="cta-secondary"
              whileHover={{ x: 5 }}
            >
              View Full Portfolio →
            </motion.a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;